@import url(https://fonts.googleapis.com/css?family=Rubik:regular,bold,italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Rubik", "sans-serif";
}

/*.react-select-container {*/
/*  background: white;*/
/*  border-radius: 5px;*/
/*}*/

/*.react-select-container > .react-select__value-container {*/
/*  background: red;*/
/*}*/

.background {
  /*padding: 6%;*/
  /*height: calc(100vh - 12%);*/
  height: 100vh;
  background: url(/static/media/background.00914f0e.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: fixed;
  top: 60px;
}

a {
  color: #003158;
  font-weight: bold;
}

.content-container {
  padding: calc(6% + 60px) 6% 6%;
  height: calc(100vh - 12%);
}

.navbar {
  position: fixed;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .2rem 6%;
  background: #ffffff;
  height: 70px;
  z-index: 2;
}

h1 {
  text-align: left;
  margin: 20px 0 0;
  font-size: 50px;
  font-weight: bold;
  z-index: 1;
  position: relative;
}

h2 {
  text-align: left;
  /*margin: 150px 0 100px;*/
  margin: 50px 0 60px;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  max-width: 600px;
}

.eater-logo {
  width: 150px;
  /*position: absolute;*/
  /*left: 30px;*/
  /*top: 30px;*/
  display: block;
}

.eater-burger-logo {
  top: 15px!important;
  position: fixed!important;
  width: 150px!important;
  margin-left: -5px!important;
  margin-top: 0!important;
}

.eater-brand-carousel-wrapper {
  padding: 20px 10px;
  background: rgba(0, 0, 0, 0);
  position: relative;
  margin: 100px auto;
  height: 60px;
}

.eater-brand-carousel {
  margin: auto;
  width: calc(100% - 90px);
}

.eater-brand-logo {
  height: 60px;
  margin: auto;
  padding: 0 20px;
  outline: none;
}

.slick-prev:before, .slick-next:before {
  color: black !important;
}

:focus {
  outline: none;
}

.secret-button {
  display: none;
}

@media only screen and (max-width: 600px) {
  h1 {
    text-align: left;
    margin: 90px 0 0;
    font-size: 36px;
    font-weight: bold;
  }

  h2 {
    margin: 30px 0 35px;
    font-size: 18px;
  }

  .eater-brand-carousel-wrapper {
    margin: 50px auto;
  }

  .eater-brand-carousel {
    width: 100%;
  }
}

